export const aboutDataOne = {
  topLine: "Background",
  description:
    "I was born and brought up in the City of Litchis, Muzaffarpur in the state of Bihar in India.\n\n" +
    "After completing my higher secondary and spending much of my teenage in my hometown, I moved to West Bengal for my Bachelors and I did my B.Tech. in Information Technology from WBUT.\n" +
    "I worked at 2 different IT firms in India for a little more than 3 years in total before I and my then girlfriend and now wife, Sakshi, decided to pursue our Masters' from Germany. " +
    "It's a story in itself and I would document our journey in a blog post soon. \n\n" +
    "Fast forward to today and I am a Senior Full Stack Software Engineer in Berlin. ",
};

export const aboutDataTwo = {
  topLine: "Hobbies",
  description:
    "I am a multipotentialite and I have interests spanning a lot of different fields.\n\n" +
    "Apart from my extreme curiosity to learn something new everyday, my innate motivation to constantly upskill myself and my love for programming, I also love to sing " +
    "and make song covers, I am into photography and film-making and I also do some sketching and writing every now and then. \n\n" +
    "I love meeting new people, sharing a laugh and always seem to have Fernweh. ",
};

export const aboutDataThree = {
  topLine: "Contact Me",
  description:
    "Feel free to reach out to me at the below mentioned email addresses for any suggestions, ideas, questions or collaborations.\n\n",
  emailOne: "kunwar.abhinav.aditya@gmail.com\n",
  emailTwo: "kaa.imps@gmail.com\n\n",
  textAfterEmails:
    "You can also contact me on any of my social handles below and I would be happy to have a word.",
};
