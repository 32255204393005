import React, { useContext } from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { ThemeContext } from "./ThemeStore";

const themes = {
  light: {
    body: "#FFF",
    text: "#121212",
    navbar: "#303030",
    bottombar: "#303030",
    navmenu: "#303030",
    linkcolor: "121212",
    logocolor: "303030",
  },
  dark: {
    body: "#101010",
    text: "#F8F8F8",
    navbar: "#101010",
    navmenu: "#101010",
    linkcolor: "F8F8F8",
    logocolor: "F8F8F8",
  },
};

const GlobalStyle = createGlobalStyle`
// * {
//   transition: all 0.05s;
// }
body {
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
}
.navbar {
  background: ${({ theme }) => theme.navbar};
}
.nav-menu.active {
  background: ${({ theme }) => theme.navmenu};
}
a {
  color: ${({ theme }) => theme.text};
}
`;

const Theme = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
