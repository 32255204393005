import React from "react";
import HeroSectionOther from "../HeroSectionOther";
import { impressumData } from "../data/ImpressumData";

function Impressum() {
    return (
        <>
            <HeroSectionOther {...impressumData} />
        </>
    );
}

export default Impressum;
