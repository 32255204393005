import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  RiLinkedinFill,
  RiYoutubeFill,
  RiFacebookFill,
  RiInstagramFill,
} from "react-icons/ri";
import "./BottomBar.css";
import { IconContext } from "react-icons/lib";
import { ThemeContext } from "./ThemeStore";
import Impressum from "./pages/Impressum";

function BottomBar() {
  const { theme, switchTheme } = useContext(ThemeContext);

  const toggleTheme = (props) => {
    switchTheme(props);
  };

  return (
    <>
      <div className="bottombar">
        <div className="impressum-container container">
          <Link to="/impressum" className="bottom-links">
            Impressum
            </Link>
        </div>
        <div className="bottombar-container container">
          <ul className="bottom-menu">
            <li className="bottom-item">
              <Link
                to={{
                  pathname:
                    "https://www.linkedin.com/in/kunwar-abhinav-aditya-21682517/",
                }}
                target="_blank"
              >
                <IconContext.Provider value={{ color: '#fff' }}>
                  <RiLinkedinFill className="bottom-icon linkedin logo" />
                </IconContext.Provider>
              </Link>
            </li>
            <li className="bottom-item">
              <Link
                to={{ pathname: "http://youtube.com/lensliebe" }}
                target="_blank"
              >
                <IconContext.Provider value={{ color: '#fff' }}>
                  <RiYoutubeFill className="bottom-icon youtube logo" />
                </IconContext.Provider>
              </Link>
            </li>
            <li className="bottom-item">
              <Link
                to={{ pathname: "https://www.facebook.com/lensliebe/" }}
                target="_blank"
              >
                <IconContext.Provider value={{ color: '#fff' }}>
                  <RiFacebookFill className="bottom-icon facebook logo" />
                </IconContext.Provider>
              </Link>
            </li>
            <li className="bottom-item">
              <Link
                to={{ pathname: "https://www.instagram.com/kunwaaart/" }}
                target="_blank"
              >
                <IconContext.Provider value={{ color: '#fff' }}>
                  <RiInstagramFill className="bottom-icon instagram logo" />
                </IconContext.Provider>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default BottomBar;
