export const objectData = {
  topLine: "Hello everyone",
  headline: "I am ",
  headLineBig: "Kunwar Abhinav Aditya,",
  description:
    "a Software Engineer by profession and an artist at heart.\n" +
    "In my free time, you can either find me immersed in tech, playing cricket or creating something new - be it a fullstack project, song cover, or a timelapse video.\n" +
    "Get in touch if you want to have a word.\n\nTake care.",
  imgStart: "",
  images: "images/Kunwar5x.png 1057w, images/Kunwar1x.png 211w",
  alt: "Kunwar Abhinav Aditya",
};
