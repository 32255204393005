import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import BottomBar from "./components/BottomBar";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import Photography from "./components/pages/Photography";
import Utilities from "./components/pages/Utilities";
import About from "./components/pages/About";
import Impressum from "./components/pages/Impressum";
import Theme from "./components/Themes";
import { ThemeStore } from "./components/ThemeStore";
import React from "react";

function App() {
  return (
    <ThemeStore>
      <Theme>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/utilities" exact component={Utilities} />
            <Route path="/about" exact component={About} />
            <Route path="/impressum" exact component={Impressum} />
          </Switch>
          <BottomBar />
        </Router>
      </Theme>
    </ThemeStore>
  );
}

export default App;
