import React from "react";
import "./HeroSectionUnderConstruction.css";

function HeroSectionUnderConstruction() {
  return (
    <>
      <div className="underconstruction__hero-section">
        <div className="underconstruction__hero-subsection">
          PAGE UNDER CONSTRUCTION
        </div>
      </div>
    </>
  );
}

export default HeroSectionUnderConstruction;
