import React from "react";
import HeroSectionUnderConstruction from "../HeroSectionUnderConstruction";

function Utilities() {
  return (
    <>
      <HeroSectionUnderConstruction />
    </>
  );
}

export default Utilities;
