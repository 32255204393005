import React from "react";
import HeroSection from "../HeroSection";
import { objectData } from "../data/Data";

function Home() {
  return (
    <>
      <HeroSection {...objectData} />
    </>
  );
}

export default Home;
