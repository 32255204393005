import React from "react";
import "./HeroSection.css";

function HeroSection({
  topLine,
  headline,
  headLineBig,
  description,
  images,
  alt,
  imgStart,
}) {
  return (
    <>
      <div className="home__hero-section">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img
                  srcSet={images}
                  src="images/Kunwar1x.png"
                  alt={alt}
                  className="home__hero-img"
                />
              </div>
            </div>
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className="heading">
                  {headline}
                  <span className="heading-big">{headLineBig}</span>
                </h1>
                <p className="home__hero-subtitle">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
