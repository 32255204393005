import React from "react";
import HeroSectionOther from "../HeroSectionOther";
import { aboutDataOne, aboutDataTwo, aboutDataThree } from "../data/AboutData";
import "../About.css";

function About() {
  return (
    <div className="about-sections">
      <HeroSectionOther {...aboutDataOne} />
      <HeroSectionOther {...aboutDataTwo} />
      <HeroSectionOther {...aboutDataThree} />
    </div>
  );
}

export default About;
