import React from "react";
import "./HeroSectionOther.css";

function HeroSectionOther({
  topLine,
  description,
  emailOne,
  emailTwo,
  textAfterEmails,
}) {
  return (
    <>
      <div className="about__hero-section">
        <div className="container">
          <div className="about__hero-col">
            <div className="about__hero-text-wrapper">
              <div className="about__top-line">{topLine}</div>
              <p className="about__hero-subtitle">{description}</p>
              <p className="about__hero-subtitle-bold">
                <a
                  className="mailLink"
                  href="mailto:kunwar.abhinav.aditya@gmail.com"
                >
                  {emailOne}
                </a>
              </p>
              <p className="about__hero-subtitle-bold">
                <a className="mailLink" href="mailto:kaa.imps@gmail.com">
                  {emailTwo}
                </a>
              </p>
              <p className="about__hero-subtitle">{textAfterEmails}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSectionOther;
